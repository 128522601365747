import React, { FC } from 'react';
import './Advantages.scss';
import Container from '../Container/Container';
import AdvantCropImg from '../../img/advant-crop.png';
import AdvantEditImg from '../../img/advant-edit.png';
import AdvantSortingImg from '../../img/advant-sorting.png';
import AdvantNoMessImg from '../../img/advant-no-mess.png';
import AdvantManageImg from '../../img/advant-manage.png';
import { useTranslation } from 'react-i18next';
import ButtonAppleStore from '../ButtonAppleStore/ButtonAppleStore';

type listType = {
  isMobile: boolean;
  src: string;
  alt: string;
};

const Advantages: FC = () => {
  const { t } = useTranslation();

  const list: listType[] = [
    {
      isMobile: true,
      src: AdvantCropImg,
      alt: 'Crop automatically'
    },
    {
      isMobile: false,
      src: AdvantEditImg,
      alt: 'Crop automatically'
    },
    {
      isMobile: false,
      src: AdvantSortingImg,
      alt: 'Crop automatically'
    },
    {
      isMobile: false,
      src: AdvantNoMessImg,
      alt: 'Crop automatically'
    },
    {
      isMobile: false,
      src: AdvantManageImg,
      alt: 'Crop automatically'
    }
  ];

  return (
    <div className="advantages">
      <div className="advantages__main">
        <Container>
          <div className="advantages__grid">
            {list.map((item) => (
              <div
                className={`advantages__column ${item.isMobile ? 'advantages__column--mobile' : ''}`}
                key={item.src}>
                <img src={item.src} alt={item.alt} />
              </div>
            ))}
          </div>

          <div className="advantages__action">
            <ButtonAppleStore
              color="#b17af6"
              href="https://apps.apple.com/us/app/document-scanner-app-lifescan/id1665727009"
              target="_blank">
              {t('downloadButton')}
            </ButtonAppleStore>
          </div>
        </Container>
      </div>

      <div className="advantages__footer">
        <ButtonAppleStore
          color="#b17af6"
          href="https://apps.apple.com/us/app/document-scanner-app-lifescan/id1665727009"
          target="_blank">
          {t('downloadButton')}
        </ButtonAppleStore>
      </div>
    </div>
  );
};

export default Advantages;
