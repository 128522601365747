import React, { useEffect } from 'react';
import Intro from '../components/Intro/Intro';
import Button from '../components/Button/Button';

function NotFoundPage({ title }: { title: string }) {
  useEffect(() => {
    document.title = title;

    return () => {
      document.title = '';
    };
  }, [title]);

  return (
    <>
      <Intro title="404" type="not-found">
        <span style={{ display: 'block', marginBottom: 20 }}>
          Sorry, nothing was found at this address
        </span>
        <Button href="/">Go to the homepage</Button>
      </Intro>
    </>
  );
}

export default NotFoundPage;
