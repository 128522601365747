import React, { useEffect } from 'react';
import Intro from '../components/Intro/Intro';
import Container from '../components/Container/Container';
import { useTranslation } from 'react-i18next';

function TermsPage({ title }: { title: string }) {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = title;

    return () => {
      document.title = '';
    };
  }, [title]);

  return (
    <>
      <Intro title={t('terms.title')} />

      <div className="content">
        <Container>
          <section>
            <h2>{t('terms.sectionIntro.title')}</h2>
            <p>{t('terms.sectionIntro.p1')}</p>
            <p>{t('terms.sectionIntro.p2')}</p>
            <p>{t('terms.sectionIntro.p3')}</p>
            <p>
              <strong>{t('terms.sectionIntro.warning')}</strong>
            </p>
            <p>{t('terms.sectionIntro.p4')}</p>
          </section>
          <section>
            <h3>{t('terms.section1.title')}</h3>
            <p>{t('terms.section1.p1')}</p>
            <p>
              {t('terms.section1.p2')}{' '}
              <a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/">
                https://www.apple.com/legal/internet-services/itunes/dev/stdeula/
              </a>
            </p>
            <p>{t('terms.section1.p3')}</p>
            <p>{t('terms.section1.p4')}</p>
            <p>{t('terms.section1.p5')}</p>
            <p>{t('terms.section1.p6')}</p>
          </section>
          <section>
            <h3>{t('terms.section2.title')}</h3>
            <p>{t('terms.section2.p1')}</p>
            <p>{t('terms.section2.p2')}</p>
            <p>
              {t('terms.section2.p3')} <br />
              {t('terms.section2.p4')}
              <br />
              {t('terms.section2.p5')}
              <br />
              {t('terms.section2.p6')}
              <br />
              {t('terms.section2.p7')}
              <br />
              {t('terms.section2.p8')}
            </p>
            <p>{t('terms.section2.p9')}</p>
            <p>{t('terms.section2.p10')}</p>
            <p>{t('terms.section2.p11')}</p>
            <p></p>
          </section>
          <section>
            <h3>{t('terms.section3.title')}</h3>
            <p>{t('terms.section3.p1')}</p>
            <p>{t('terms.section3.p2')}</p>
            <p>{t('terms.section3.p3')}</p>
            <p>{t('terms.section3.p4')}</p>
            <p>{t('terms.section3.p5')}</p>
            <p>{t('terms.section3.p6')}</p>
          </section>
          <section>
            <h3>{t('terms.section4.title')}</h3>
            <p>
              {t('terms.section4.p1')}
              <br />
              {t('terms.section4.p2')}
              <br />
              {t('terms.section4.p3')}
              <br />
              {t('terms.section4.p4')}
              <br />
              {t('terms.section4.p5')}
            </p>
            <p>{t('terms.section4.p6')}</p>
            <p>
              {t('terms.section4.p7')}
              <br />
              {t('terms.section4.p8')}
            </p>
            <p>
              {t('terms.section4.p9')} <br />
              {t('terms.section4.p10')}
              <br />
              {t('terms.section4.p11')}
              <br />
              {t('terms.section4.p12')}
              <br />
              {t('terms.section4.p13')}
              <br />
              {t('terms.section4.p14')}
              <br />
              {t('terms.section4.p5')}
              <br />
              {t('terms.section4.p16')}
              <br />
              {t('terms.section4.p17')}
              <br />
              {t('terms.section4.p18')}
              <br />
              {t('terms.section4.p19')}
              <br />
              {t('terms.section4.p20')}
              <br />
              {t('terms.section4.p21')}
              <br />
              {t('terms.section4.p22')}
              <br />
              {t('terms.section4.p23')}
              <br />
              {t('terms.section4.p24')}
            </p>
          </section>
          <section>
            <h3>{t('terms.section5.title')}</h3>
            <p>{t('terms.section5.p1')}</p>
            <p>{t('terms.section5.p2')}</p>
            <p>{t('terms.section5.p3')}</p>
            <p>{t('terms.section5.p4')}</p>
            <p>{t('terms.section5.p5')}</p>
            <p>{t('terms.section5.p6')}</p>
          </section>

          <p>{t('terms.warning')}</p>

          <p>
            {t('terms.contact')}
            <a href="mailto:info@lifescan.site" style={{ backgroundColor: 'rgb(245, 245, 245)' }}>
              info@lifescan.site
            </a>
          </p>

          <p>{t('terms.update')}</p>
        </Container>
      </div>
    </>
  );
}

export default TermsPage;
