import React, { useEffect } from 'react';
import Intro from '../components/Intro/Intro';
import Container from '../components/Container/Container';
import { useTranslation } from 'react-i18next';

function PrivacyPage({ title }: { title: string }) {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = title;

    return () => {
      document.title = '';
    };
  }, [title]);

  return (
    <>
      <Intro title={t('privacy.title')} />

      <div className="content">
        <Container>
          <section>
            <h2>{t('privacy.sectionIntro.title')}</h2>
            <p>{t('privacy.sectionIntro.p1')}</p>
            <p>{t('privacy.sectionIntro.p2')}</p>
            <p>{t('privacy.sectionIntro.p3')}</p>
            <p>
              <strong>{t('privacy.sectionIntro.warning')}</strong>
            </p>
            <p>
              {t('privacy.sectionIntro.p4')}
              <br />
              {t('privacy.sectionIntro.p5')}
              <br />
              {t('privacy.sectionIntro.p6')}
              <br />
              {t('privacy.sectionIntro.p7')}
              <br />
              {t('privacy.sectionIntro.p8')}
              <br />
              {t('privacy.sectionIntro.p9')}
            </p>
            <p>
              {t('privacy.sectionIntro.p10')}
              <a href="mailto:info@lifescan.site" style={{ backgroundColor: 'rgb(245, 245, 245)' }}>
                info@lifescan.site
              </a>
            </p>
            <p>{t('privacy.sectionIntro.p11')}</p>
          </section>
          <section>
            <h3>{t('privacy.section1.title')}</h3>
            <p>{t('privacy.section1.p1')}</p>
            <p>{t('privacy.section1.p2')}</p>
          </section>
          <section>
            <h3>{t('privacy.section2.title')}</h3>
            <p>{t('privacy.section2.p1')}</p>
            <p>{t('privacy.section2.p2')}</p>
          </section>
          <section>
            <h3>{t('privacy.section3.title')}</h3>
            <p>{t('privacy.section3.p1')}</p>
            <p>{t('privacy.section3.p2')}</p>
            <p>{t('privacy.section3.p3')}</p>
          </section>
          <section>
            <h3>{t('privacy.section4.title')}</h3>
            <p>{t('privacy.section4.p1')}</p>
            <p>{t('privacy.section4.p2')}</p>
            <p>{t('privacy.section4.p3')}</p>
            <p>{t('privacy.section4.p4')}</p>
            <p>
              {t('privacy.section4.p5')} <br />
              <a href="https://www.apple.com/privacy/">https://www.apple.com/privacy/</a>
              <br />
              <a href="https://www.apple.com/ru/legal/privacy/ru/">
                https://www.apple.com/ru/legal/privacy/ru/
              </a>
            </p>
          </section>
          <section>
            <h3>{t('privacy.section5.title')}</h3>
            <p>
              {t('privacy.section5.p1')} <br />
              {t('privacy.section5.p2')}
              <br />
              {t('privacy.section5.p3')}
              <br />
            </p>
            <p>
              {t('privacy.section5.p4')} <br />
              Firebase{' '}
              <a href="https://firebase.google.com/support/privacy/">
                https://firebase.google.com/support/privacy/
              </a>
              <br />
              Amplitude{' '}
              <a href="https://amplitude.com/privacy" target="_blank" rel="noreferrer noopener">
                https://amplitude.com/privacy
              </a>{' '}
              <br />
              AppsFlyer{' '}
              <a
                href="https://www.appsflyer.com/legal/privacy-policy/"
                target="_blank"
                rel="noreferrer noopener">
                https://www.appsflyer.com/legal/privacy-policy/{' '}
              </a>
              <br />
              AppMetrica{' '}
              <a
                href="https://yandex.com/legal/confidential/"
                target="_blank"
                rel="noreferrer noopener">
                https://yandex.com/legal/confidential/
              </a>
              <br />
              Apphud{' '}
              <a href="https://legal.apphud.com/privacy" target="_blank" rel="noreferrer noopener">
                https://legal.apphud.com/privacy
              </a>
              <br />
              SmartLook{' '}
              <a
                href="https://help.smartlook.com/docs/privacy-policy"
                target="_blank"
                rel="noreferrer noopener">
                https://help.smartlook.com/docs/privacy-policy
              </a>{' '}
            </p>
            <p>{t('privacy.section5.p5')}</p>
          </section>
          <section>
            <h3>{t('privacy.section6.title')}</h3>
            <p>{t('privacy.section6.p1')}</p>
            <p>{t('privacy.section6.p2')}</p>
          </section>
          <section>
            <h3>{t('privacy.section7.title')}</h3>
            <p>{t('privacy.section7.p1')}</p>
          </section>
          <section>
            <h3>{t('privacy.section8.title')}</h3>
            <p>{t('privacy.section8.p1')}</p>
          </section>
          <p>{t('privacy.section8.p2')}</p>
          <p>
            {t('privacy.section8.p3')} <a href="mailto:info@lifescan.site">info@lifescan.site</a>
          </p>
          <p>{t('privacy.update')}</p>
        </Container>
      </div>
    </>
  );
}

export default PrivacyPage;
