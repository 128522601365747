import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Footer.scss';
import Container from '../Container/Container';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClickMenu = (url: string) => {
    if (url === pathname) {
      document.documentElement.classList.add('smooth-scroll');
      setTimeout(() => window.scrollTo(0, 0), 100);
      setTimeout(() => document.documentElement.classList.remove('smooth-scroll'), 1000);
    } else {
      navigate(url);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <footer className="footer">
      <Container>
        <a href="mailto:info@lifescan.site">info@lifescan.site</a>
        <ul className="footer__menu">
          <li>
            <span onClick={() => handleClickMenu('/')}>{t('menu.home')}</span>
          </li>
          <li>
            <span onClick={() => handleClickMenu('/terms')}>{t('menu.terms')}</span>
          </li>
          <li>
            <span onClick={() => handleClickMenu('/privacy')}>{t('menu.privacy')}</span>
          </li>
        </ul>
      </Container>
    </footer>
  );
}

export default Footer;
