import React, { FC, ReactNode, AnchorHTMLAttributes } from 'react';
import './Button.scss';

type ButtonProps = {
  href: string;
  type?: string;
  children: ReactNode;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

const Button: FC<ButtonProps> = ({ href, type = 'primary', children, ...props }) => {
  return (
    <a href={href} className={`button button--${type}`} {...props}>
      {children}
      <span className="button__wrap">
        <span className="button__effect"></span>
      </span>
    </a>
  );
};

export default Button;
