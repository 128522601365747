import React, { FC, ReactNode } from 'react';
import './Intro.scss';
import { useTranslation } from 'react-i18next';
import ButtonAppleStore from '../ButtonAppleStore/ButtonAppleStore';

type IntroProps = {
  title: string | ReactNode;
  type?: string;
  children?: ReactNode;
};

const Intro: FC<IntroProps> = ({ title, children, type }) => {
  const { t } = useTranslation();

  console.log(type);

  return (
    <div className={`intro intro--${type}`}>
      <div className="intro__inner">
        <div className="container">
          <div className="intro__main">
            <h1>{title}</h1>
            {children && <div className="intro__content">{children}</div>}
          </div>
        </div>
      </div>

      {children && (
        <div className="intro__footer">
          <ButtonAppleStore
            color="#b17af6"
            href="https://apps.apple.com/us/app/document-scanner-app-lifescan/id1665727009"
            target="_blank">
            {t('downloadButton')}
          </ButtonAppleStore>
        </div>
      )}
    </div>
  );
};

export default Intro;
