import React, { Suspense } from 'react';
import Footer from './components/Footer/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import NotFoundPage from './pages/NotFoundPage';

function App() {
  return (
    <Suspense>
      <div className="App">
        <BrowserRouter basename="/">
          <Routes>
            <Route
              path="/"
              element={
                <HomePage title="Lifescan. Convenient Mobile Scanning App | Fast & User-Friendly" />
              }
            />
            <Route path="/terms" element={<TermsPage title="Terms" />} />
            <Route path="/privacy" element={<PrivacyPage title="Privacy" />} />

            <Route path="*" element={<NotFoundPage title="404 Not Found" />} />
          </Routes>

          <Footer />
        </BrowserRouter>
      </div>
    </Suspense>
  );
}

export default App;
